<template>
  <div
    id="hero"
    class="hero container-full"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container">
      <h1 class="animate-on-load animate-on-load-1">Expert Exchange Video Hub:<br class="only-desktop"> Clinical Trial in <nobr>4th-Line</nobr> Advanced GIST</h1>
      <p class="hero-descr animate-on-load animate-on-load-2">Join leading gastrointestinal stromal tumor (GIST) experts as they deep dive into<br class="only-desktop"> ripretinib (QINLOCK) clinical data from the Phase 3 INVICTUS study.</p>
      <p class="hero-descr-bottom animate-on-load animate-on-load-2">QINLOCK is a kinase inhibitor indicated for the treatment of adult patients with advanced gastrointestinal stromal tumor (GIST) who have received prior treatment with 3 or more kinase inhibitors, including imatinib.</p>
      <div class="about-experts animate-on-load animate-on-load-3">
        <div class="about-experts-logo">
          <img src="@/assets/img/icon/experts-icon.svg" alt="">
          <h2>About The Experts</h2>
        </div>
        <div class="about-experts-expert e1">
          <img src="@/assets/img/hero/expert-1.jpg" alt="">
          <div>
            <b>Sebastian Bauer, MD</b>
            Senior Physician, Sarcoma Center<br>
            Medical Oncology Sarcoma Center<br>
            West German Cancer Center
          </div>
        </div>
        <div class="about-experts-expert e2">
          <img src="@/assets/img/hero/expert-2.jpg" alt="">
          <div>
            <b>Gina D’Amato, MD</b>
            Associate Professor of Medicine at the<br>
            Sylvester Comprehensive Cancer Center<br>
            University of Miami
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "HeroSection",
  components: {},
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
  },
  mounted() {
    ScrollTrigger.create({
      trigger: this.$el,
      start: `top center`,
      end: `bottom center`,
      once: true,
      onToggle: ({progress, direction, isActive}) => {
        // if (isActive && typeof window.dataLayer !== 'undefined' && window.dataLayer) {
        //   window.dataLayer.push({
        //     event: "section-view",
        //     section: 'Overview',
        //     subsection: "-",
        //   });
        // }
      },
    });
  },
};
</script>

<style lang="scss">
.hero {
  padding-top: 80px;
  padding-bottom: 40px;

  @media (max-width: $tablet-max) {
    padding-bottom: 20px;
  }

  h1 {
    color: $raspberry;

    @media (max-width: $mobile-max) {
      margin-top: 0;
      font-size: 32px;
      line-height: 40px;
    }
  }

  .hero-descr {
    display: block;
    color: $text1;
    text-align: center;
    margin: 0 auto 10px;

    @media (max-width: $tablet-max) {
      font-size: 18px;
      line-height: 25px;
    }

    &-bottom {
      display: block;
      color: $text1;
      text-align: center;
      margin: 0 auto 48px;

      @media (max-width: $tablet-max) {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }
  }

  .about-experts {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 20px 0;

    @media (max-width: $tablet-max) {
      padding: 40px 0;
    }

    @media (max-width: $mobile-max) {
      flex-direction: column;
      padding: 30px 0;
    }

    & > * {
      margin: 0 10px;

      @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
        margin: 0 5px;
      }

      @media (max-width: $mobile-max) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 12%;

      @media (min-width: 1280px) {
        min-width: 180px;
      }

      @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
        min-width: 110px;
      }

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        min-width: 100px;
        max-width: 100px;
      }

      img {
        max-height: 40px;

        @media (max-width: $tablet-max) {
          max-height: unset;
        }

        @media (max-width: $mobile-max) {
          margin-bottom: 10px;
        }
      }

      h2 {
        max-width: 140px;

        @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
          font-size: 18px;
        }

        @media (max-width: $tablet-max) {
          font-size: 20px;
        }

        @media (max-width: $mobile-max) {
          font-size: 24px;
          line-height: 32px;
          max-width: unset;
        }
      }
    }

    &-expert {
      position: relative;
      display: flex;
      flex-grow: 1;
      background: rgb(255 255 255 / 65%);
      border: 1px solid $raspberry;

      @media (max-width: $tablet-max) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px;
        padding: 30px 20px 24px;
      }

      @media (min-width: $tablet-min) and (max-width: 899px) {
        padding: 30px 10px 24px;
      }

      @media (max-width: $mobile-max) {
        margin-top: 20px;
      }

      &::after {
        position: absolute;
        content: "";
        background: center/100% 100% url("~@/assets/img/icon/expert-top-right-chevron.svg") no-repeat;
        top: -4px;
        right: -4px;
        width: 36px;
        height: 36px;

        @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
          top: -3px;
          right: -3px;
          width: 24px;
          height: 24px;
        }
      }

      img {
        border-right: 4px solid $orange;
        max-height: 110px;

        @media (min-width: 1360px) {
          max-height: 130px;
        }

        @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
          max-height: 90px;
        }

        @media (max-width: $tablet-max) {
          min-width: 120px;
          max-width: 120px;
          min-height: 120px;
          max-height: 120px;
          border: 3px solid $orange;
          border-radius: 60px;
        }
      }

      div {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        line-height: 125%;
        color: $text1;
        padding: 10px 10px 10px 20px;

        @media (min-width: 1360px) {
          font-size: 14px;
        }

        @media (min-width: $desktop-xs-max) and (max-width: 1230px) {
          letter-spacing: -0.4px;
        }

        @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
          font-size: 12px;
          padding: 5px 0 5px 10px;
        }

        @media (max-width: $tablet-max) {
          padding: 20px 0 0;
          text-align: center;
          font-size: 12px;
          letter-spacing: -0.3px;
          line-height: 140%;
        }

        @media (max-width: $mobile-max) {
          font-size: 13px;
          line-height: 18px;
        }

        b {
          font-size: 15px;
          font-weight: 700;
          color: $raspberry;
          margin-bottom: 0.3em;

          @media (min-width: 1360px) {
            font-size: 16px;
          }

          @media (min-width: $desktop-min) and (max-width: $desktop-xs-max) {
            font-size: 14px;
          }

          @media (max-width: $mobile-max) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

</style>