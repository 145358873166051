const state = {
  isOpenedISI: false,
  isOpenedMobileLeftColumnISI: false,
  isOpenedMobileRightColumnISI: false,
};

const getters = {};

const mutations = {
  toggleISIState(state) {
    state.isOpenedISI = !state.isOpenedISI;
  },
  toggleMobileLeftColumnISIState(state) {
    state.isOpenedMobileLeftColumnISI = !state.isOpenedMobileLeftColumnISI;
    state.isOpenedMobileRightColumnISI = false;
    // document.querySelector(
    //   "html"
    // ).style.overflow = state.isOpenedMobileLeftColumnISI ? "hidden" : "auto";
  },
  toggleMobileRightColumnISIState(state) {
    state.isOpenedMobileRightColumnISI = !state.isOpenedMobileRightColumnISI;
    state.isOpenedMobileLeftColumnISI = false;
    // document.querySelector(
    //   "html"
    // ).style.overflow = state.isOpenedMobileRightColumnISI ? "hidden" : "auto";
  },
  closeAllMobileISI(state) {
    state.isOpenedMobileLeftColumnISI = false;
    state.isOpenedMobileRightColumnISI = false;
  },
  closeDesktopISI(state) {
    state.isOpenedISI = false;
  },
};

const actions = {
  toggleISIState({ commit }) {
    commit("toggleISIState");
  },
  toggleMobileLeftColumnISIState({ commit }) {
    commit("toggleMobileLeftColumnISIState");
  },
  toggleMobileRightColumnISIState({ commit }) {
    commit("toggleMobileRightColumnISIState");
  },
  closeAllMobileISI({ commit }) {
    commit("closeAllMobileISI");
  },
  closeDesktopISI({ commit }) {
    commit("closeDesktopISI");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
