const state = {
  isScreentest: false,
};

const getters = {};

const mutations = {
  setScreentest(state) {
    state.isScreentest = true;
  },
};

const actions = {
  setScreentest({ commit }) {
    commit("setScreentest");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
