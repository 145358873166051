<template>
  <div
    class="isi"
    :class="{opened}"
  >
    <div class="isi__body">
      <div class="isi__body-scroll">
        <ISIContent />
      </div>

      <button
        class="isi__expand-button"
        @click="toggleISI()"
      >
        <ISIArrow />
      </button>
    </div>
  </div>
</template>

<script>
import ISIArrow from "@/components/ISI/ISIArrow";
import ISIContent from "@/components/ISI/ISIContent";
import LockBodyScroll from "@/mixins/LockBodyScroll";
import { mapState } from "vuex";

export default {
  name: "ISI",
  components: {
    ISIArrow,
    ISIContent
  },
  mixins: [LockBodyScroll],
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  methods: {
    toggleISI() {
      if (!this.opened) {
        this.lockBodyScroll();
      } else {
        if (!document.body.classList.contains('menu__opened')) {
          this.unLockBodyScroll();
        }
        document.querySelector(".isi .isi__body-scroll").scrollTo(0, 0)
      }

      this.opened = !this.opened;
    },
  },
};
</script>
