<template>
  <div
    ref="homeRoot"
    class="home"
  >
    <HeaderBar />
    <HeroSection />
    <DataSection />
    <ResourcesSection />
    <CalloutSection />
      <div
        class="main-container"
        :class="{'isi-opened': isiOpened}"
      >
        <ISIMain />
      </div>
    <FooterSection />
  </div>
</template>

<script>
import ScrollDepthAnalytics from "@/mixins/ScrollDepthAnalytics";
import HeaderBar from "@/components/Content/HeaderBar.vue";
import HeroSection from "@/components/Content/HeroSection";
import ISIMain from "@/components/ISI/ISIMain";
import FooterSection from "@/components/Content/FooterSection.vue";
import DataSection from "@/components/Content/DataSection.vue";
import ResourcesSection from "@/components/Content/ResourcesSection.vue";
import CalloutSection from "@/components/Content/CalloutSection.vue";

export default {
  name: "HomePage",
  components: {
    CalloutSection,
    ResourcesSection,
    DataSection,
    HeaderBar,
    HeroSection,
    ISIMain,
    FooterSection
  },
  mixins: [
       ScrollDepthAnalytics
  ],
  data() {
    return {
      isiOpened: false
    };
  },
  methods: {
    showHideISI() {
      if (this.isiMain.getBoundingClientRect().top < window.innerHeight * 0.85 - 80) {
        this.isiFixed.classList.add('hidden')
      } else if (this.isiFixed.classList.contains('hidden'))  {
        this.isiFixed.classList.remove('hidden')
        this.isiFixed.querySelector('.isi__body-scroll').scrollTo(0,0)
      }
    }
  },
  mounted() {
    this.initScrollDepthAnalytics();
    this.isiFixed = document.querySelector('.isi')
    this.isiMain = document.querySelector('.isi-main .isi__inner-s')
    window.addEventListener('scroll', this.showHideISI)
    window.addEventListener('resize', this.showHideISI)
  },
};
</script>

<style lang="scss">
  .home {
    background: url("~@/assets/img/bg/blobs.svg") no-repeat;
    background-size: 100vw auto;
    background-position: left top;
  }

  #app:not(.screentest) {
    .animate-on-load {
      opacity: 0;
      transition: 0.7s ease-in;

      &-2 {
        transition-delay: 0.4s;
      }

      &-3 {
        transition-delay: 0.8s;
      }

      &-4 {
        transition-delay: 1.2s;
      }
    }
  }

  body.loaded {
    #app:not(.screentest) {
      .animate-on-load {
        opacity: 1;
      }
    }
  }
</style>
