<template>
  <div class="isi__inner isi__inner-s container">
    <h2>Important Safety Information</h2>
    <p>
      There are no contraindications for QINLOCK.
    </p>
    <p>
      <b>Palmar-plantar erythrodysesthesia syndrome (PPES):</b> In INVICTUS, Grade 1-2 PPES occurred in 21% of the 85
      patients who received QINLOCK. PPES led to dose discontinuation in 1.2% of patients, dose interruption in 2.4% of
      patients, and dose reduction in 1.2% of patients. Based on severity, withhold QINLOCK and then resume at same or
      reduced dose.
    </p>
    <p>
      <b>New Primary Cutaneous Malignancies:</b> In INVICTUS, cutaneous squamous cell carcinoma (cuSCC) occurred in 4.7%
      of the 85 patients who received QINLOCK with a median time to event of 4.6 months (range 3.8 to 6 months). In the
      pooled safety population, cuSCC and keratoacanthoma occurred in 7% and 1.9% of patients, respectively. In INVICTUS,
      melanoma occurred in 2.4% of the 85 patients who received QINLOCK. In the pooled safety population, melanoma
      occurred in 0.9% of patients. Perform dermatologic evaluations when initiating QINLOCK and routinely during
      treatment. Manage suspicious skin lesions with excision and dermatopathologic evaluation. Continue QINLOCK at
      the same dose.
    </p>
    <p>
      <b>Hypertension:</b> In INVICTUS, Grade 1-3 hypertension occurred in 14% of the 85 patients who received QINLOCK,
      including Grade 3 hypertension in 7% of patients. Do not initiate QINLOCK in patients with uncontrolled
      hypertension. Monitor blood pressure as clinically indicated. Based on severity, withhold QINLOCK and then resume
      at same or reduced dose or permanently discontinue.
    </p>
    <p>
      <b>Cardiac Dysfunction:</b> In INVICTUS, cardiac failure occurred in 1.2% of the 85 patients who received QINLOCK.
      In the pooled safety population, cardiac dysfunction (including cardiac failure, acute left ventricular failure,
      diastolic dysfunction, and ventricular hypertrophy) occurred in 1.7% of patients, including Grade 3 adverse
      reactions in 1.1% of patients.
    </p>
    <p>
      In INVICTUS, Grade 3 decreased ejection fraction occurred in 2.6% of the 77 patients who received QINLOCK and who
      had a baseline and at least one post-baseline echocardiogram. Grade 3 decreased ejection fraction occurred in 3.4%
      of the 263 patients who received QINLOCK and who had a baseline and at least one post-baseline echocardiogram.
    </p>
    <p>
      In INVICTUS, cardiac dysfunction led to dose discontinuation in 1.2% of the 85 patients who received QINLOCK.
      The safety of QINLOCK has not been assessed in patients with a baseline ejection fraction below 50%. Assess
      ejection fraction by echocardiogram or MUGA scan prior to initiating QINLOCK and during treatment, as clinically
      indicated. Permanently discontinue QINLOCK for Grade 3 or 4 left ventricular systolic dysfunction.
    </p>
    <p>
      <b>Risk of Impaired Wound Healing:</b> QINLOCK has the potential to adversely affect wound healing. Withhold
      QINLOCK for at least 1 week prior to elective surgery. Do not administer for at least 2 weeks following major
      surgery and until adequate wound healing. The safety of resumption of QINLOCK after resolution of wound healing
      complications has not been established.
    </p>
    <p>
      <b>Photosensitivity:</b> QINLOCK may cause photosensitivity reactions. In 621 patients treated with QINLOCK in
      clinical trials, photosensitivity reactions occurred in 0.6% of patients. Advise patients to limit direct
      ultraviolet exposure during treatment with QINLOCK and for at least 1 week after discontinuation of treatment.
    </p>
    <p>
      <b>Embryo-Fetal Toxicity:</b> QINLOCK can cause fetal harm when administered to a pregnant woman. Advise pregnant
      women of the potential risk to a fetus. Advise females of reproductive potential and males with female partners of
      reproductive potential to use effective contraception during treatment and for 1 week after the last dose. Because
      of the potential for serious adverse reactions in the breastfed child, advise women not to breastfeed during
      treatment and for 1 week after the last dose. QINLOCK may impair fertility in males of reproductive potential.
    </p>
    <p>
      <b>Adverse Reactions:</b> The most common adverse reactions (≥20%) were alopecia, fatigue, nausea, abdominal pain,
      constipation, myalgia, diarrhea, decreased appetite, PPES, and vomiting. The most common Grade 3 or 4 laboratory
      abnormalities (≥4%) were increased lipase and decreased phosphate.
    </p>
    <p>
      The safety and effectiveness of QINLOCK in pediatric patients have not been established.
    </p>
    <p>
      Administer strong CYP3A inhibitors with caution. Monitor patients who are administered strong CYP3A inhibitors
      more frequently for adverse reactions. Avoid concomitant use with strong and moderate CYP3A inducers. If a
      moderate CYP3A inducer cannot be avoided, increase QINLOCK dosing frequency from the recommended dose of 150 mg
      once daily to 150 mg twice daily during the co-administration period. If the concomitant moderate CYP3A inducer is
      discontinued, resume QINLOCK dosage back to 150 mg once daily 14 days after the discontinuation of the moderate
      CYP3A inducer.
    </p>
    <p class="mb-16">
      <b>
        To report SUSPECTED ADVERSE REACTIONS, contact Deciphera Pharmaceuticals, LLC, at
        <a href="tel:18887243274" class="body-link"><nobr>1-888-724-3274</nobr></a> or FDA at
        <a href="tel:18003321088" class="body-link"><nobr>1-800-FDA-1088</nobr></a> or
        <a href="https://www.fda.gov/medwatch" class="body-link" target="_blank">www.fda.gov/medwatch.</a>
      </b>
    </p>
    <h2>Indication</h2>
    <p>
      QINLOCK is a kinase inhibitor indicated for the treatment of adult patients with advanced gastrointestinal stromal
      tumor (GIST) who have received prior treatment with 3 or more kinase inhibitors, including imatinib.
    </p>
    <p>
      <b>Please see full
        <a
            class="body-link"
            href="https://www.qinlockhcp.com/Content/files/qinlock-prescribing-information.pdf"
            target="_blank"
            @click="piClick"
        >
          Prescribing Information</a>, including Patient Information.
      </b>
    </p>
  </div>
</template>

<script>
import DataLayer from "@/mixins/DataLayer";

export default {
  name: "ISIContent",
  mixins: [DataLayer],
  methods: {
    piClick() {
      // this.clickEventPush("Prescribing Information")
    },
  }
};
</script>