export default {
    methods: {
        buttonClickDataLayerPush(buttonName) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    'event': 'button-click',
                    'page-name': 'landing',
                    'button-name': buttonName
                });
            }
        }
    }
}