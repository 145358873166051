const DataLayer = {
  methods: {
    clickEventPush(buttonName, pageName = '') {
      if (window.dataLayer) {
        if (pageName === '') {
          pageName = "vabysmo-ff"
        }
        window.dataLayer.push({
          event: "button-click",
          "page-name": `${pageName}`,
          "button-name": `${buttonName}`,
        });
      }
    },
  }
};

export default DataLayer;
