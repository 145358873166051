import cloudinary from "cloudinary-core";

// eslint-disable-next-line no-unused-vars
const cl = new cloudinary.Cloudinary({
  cloud_name: "haymarket-pri",
  api_key: "961798978258628",
  api_secret: "hkaw1wxCsGWoALtW2BRvsfwWw5w",
  invalidate: true,
});

/*
  Example
  // 1: [],
  // 2: [],
*/
const animationsData = {
  1: [
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.4,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/1/1.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.8,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/1/2.svg"),
      type: "mask",
      maskDirection: "from-left",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.2,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/1/3.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.6,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/1/4.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
  ],
  101: [
    {
      direction: "animated",
      duration: 0,
      delay: 0,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/mob/1.png"),
      type: "fade",
      altText: "",
      isMobile: true
    },
  ],
  2: [
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.4,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/1.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.8,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/2.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.2,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/3.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.6,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/4.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 2.0,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/5.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 2.4,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/6.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 2.8,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/7.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 3.2,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/2/8.svg"),
      type: "mask",
      maskDirection: "from-bottom",
      altText: "",
      isMobile: false
    },
  ],
  102: [
    {
      direction: "animated",
      duration: 0,
      delay: 0,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/mob/2.png"),
      type: "fade",
      altText: "",
      isMobile: true
    },
  ],
  3: [
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.4,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/3/1.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 0.8,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/3/2.svg"),
      type: "mask",
      maskDirection: "from-left",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.2,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/3/3.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 1.6,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/3/4.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
    {
      direction: "animated",
      duration: 0.8,
      delay: 2.0,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/3/5.svg"),
      type: "fade",
      altText: "",
      isMobile: false
    },
  ],
  103: [
    {
      direction: "animated",
      duration: 0,
      delay: 0,
      src: cl.url("https://res.cloudinary.com/haymarket-pri/image/upload/v1682445412/Genentech%20Vabysmo%20FF/mob/3.png"),
      type: "fade",
      altText: "",
      isMobile: true
    },
  ],
};

export default animationsData;
