<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ButtonClickAnalytics from "@/mixins/ButtonClickAnalytics";

export default {
  name: "CalloutSection",
  computed: {
    ...mapState(["UserAgent"]),
  },
  mixins: [ButtonClickAnalytics],
  methods: {
  },
  mounted() {
    ScrollTrigger.create({
      trigger: this.$el,
      start: `top center`,
      end: `bottom center`,
      once: true,
      onToggle: ({progress, direction, isActive}) => {
        this.$el.classList.add('start-animation')
        setTimeout(() => {
          this.$el.classList.add('animated')
        }, 1000)
        // if (isActive && typeof window.dataLayer !== 'undefined' && window.dataLayer) {
        //   window.dataLayer.push({
        //     event: "section-view",
        //     section: 'Overview',
        //     subsection: "-",
        //   });
        // }
      },
    });
  },
};
</script>

<template>
  <div
    id="callout"
    class="callout container-full"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container-full">
      <div class="container">
        <div class="title">
          <h3 class="animate animate-1">Your Next Move</h3>
          <img class="animate animate-1" src="@/assets/img/cta/gradient-arrow-right.svg" alt="">
        </div>
        <div class="buttons">
          <a href="https://www.qinlockhcp.com/" class="rasp-btn animate animate-2" target="_blank" @click="buttonClickDataLayerPush('Learn More')">LEARN MORE ABOUT QINLOCK</a>
          <a href="https://www.qinlockhcp.com/expert-exchange" class="rasp-btn animate animate-3" target="_blank" @click="buttonClickDataLayerPush('Hear from other Experts')">HEAR FROM OTHER EXPERTS</a>
          <a href="https://www.qinlockhcp.com/#request-information" class="rasp-btn animate animate-4" target="_blank" @click="buttonClickDataLayerPush('Request a Rep')">REQUEST A REP</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#app:not(.screentest) {
  .animate {
    opacity: 0;
    transition: 0.4s ease-in;

    &-2 {
      transition-delay: 0.3s;
    }

    &-3 {
      transition-delay: 0.6s;
    }

    &-4 {
      transition-delay: 0.9s;
    }
  }

  .callout.start-animation {
    .animate {
      opacity: 1;
    }

    &.animated {
      .animate {
        transition-delay: 0s;
      }
    }
  }
}

.callout {
  padding-top: 40px;

  @media (max-width: $mobile-max) {
    padding-top: 25px;
  }

  .container-full {
    display: flex;
    background: #edf2f7;
    padding-top: 40px !important;
    padding-bottom: 40px !important;

    @media (max-width: $tablet-max) {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }

    @media (max-width: $mobile-max) {
      padding: 0 !important;
    }

    .container {
      display: flex;

      @media (max-width: $mobile-max) {
        flex-direction: column;
      }
    }

    .title,
    .buttons {
      display: flex;
      align-items: center;
    }

    .title {
      flex-grow: 1;
      justify-content: center;
      border-right: 1px solid $border-color;
      padding: 40px 30px;
      background: left bottom url("~@/assets/img/cta/left-bottom-chevron.svg") no-repeat;

      @media (min-width: $desktop-min) and (max-width: 1300px) {
        flex-direction: column;
        padding: 0 20px;
      }

      @media (max-width: $tablet-max) {
        flex-grow: unset;
        width: 60%;
      }

      @media (max-width: $mobile-max) {
        width: 100%;
        border: none;
        background: none;
        padding: 30px 0;
        border-bottom: 2px solid $border-color;
      }

      h3 {
        margin-right: 20px;
        text-align: center;
        color: #000;

        @media (min-width: $desktop-min) and (max-width: 1300px) {
          margin-right: 0;
          margin-bottom: 10px;
        }

        @media (max-width: $mobile-max) {
          font-size: 26px;
          line-height: 35px;
        }
      }

      img {
        @media (max-width: $mobile-max) {
          transform: rotate(90deg);
        }
      }
    }

    .buttons {
      padding-left: 20px;

      @media (max-width: $tablet-max) {
        width: 40%;
      }

      @media (max-width: $tablet-max) {
        flex-direction: column;
      }

      @media (max-width: $mobile-max) {
        width: 100%;
        padding: 24px 14px 110px;
        background: left bottom 30px/48px 48px url("~@/assets/img/cta/left-bottom-chevron.svg") no-repeat;
      }

      & > * {
        margin-left: 20px;
        letter-spacing: -0.3px;
        text-align: center;

        @media (max-width: $tablet-max) {
          width: 100%;
          margin: 8px 0;
        }

        @media (max-width: $mobile-max) {
          text-align: left;
          justify-content: space-between;
          font-size: 15px;
        }

        @media (max-width: 375px) {
          padding: 18px;
          font-size: 14px;
        }
      }
    }
  }
}

</style>