<template>
  <div class="rotate-wrapper">
    <div class="rotate-wrapper__content">
      <div class="rotate-wrapper__circle">
        <div class="rotate-wrapper__phone" />
      </div>
      <span class="rotate-wrapper__text">Please rotate your device</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RotateLock",
  data() {
    return {};
  },
  computed: {
    ...mapState(["rotateLock"]),
  },
  beforeMount() {
    this.$store.commit("SET_ROTATE_LOCK", true);
    document.body.classList.add('rotate-lock')
  },
  beforeDestroy() {
    document.body.classList.remove('rotate-lock')
  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.rotate-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background-color: #000;
  position: fixed;

  &__content {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__circle {
    background: url("./assets/rotate-circle.svg") no-repeat;
    width: 125px;
    height: 116px;
    position: relative;
    animation: wiggle 1.5s infinite;
  }

  &__phone {
    background: url("./assets/rotate-device.svg") no-repeat center;
    width: 100%;
    height: 100%;
    left: -5px;
    position: absolute;
  }

  &__text {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    color: #fff;
    display: block;
    margin-top: 20px;
  }
}

.screentest .rotate-wrapper {
  display: none;
}

@keyframes wiggle {
  0% {
    transform: rotate(1deg);
  }

  50% {
    transform: rotate(-91deg);
  }

  100% {
    transform: rotate(1deg);
  }
}
</style>
