const state = {
  isOpenedModal: false,
};

const getters = {};

const mutations = {
  openModal(state) {
    state.isOpenedModal = true;
  },
  closeModal(state) {
    state.isOpenedModal = false;
  },
};

const actions = {
  openModal({ commit }) {
    commit("openModal");
  },
  closeModal({ commit }) {
    commit("closeModal");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
