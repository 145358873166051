<template>
  <div
    id="isi_static"
    class="isi-main"
  >
    <ISIContent />
  </div>
</template>

<script>
import ISIContent from "@/components/ISI/ISIContent";

export default {
  name: "ISIMain",
  components: {
    ISIContent,
  },
  methods: {},
};
</script>
