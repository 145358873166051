const UserAgent = {
  state: {
    window: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    orientation: null,
    isMobileDevice: false
  },
  mutations: {
    DETECT_ORIENTATION(state, payload) {
      if (payload) {
        state.orientation = "landscape";
      } else {
        state.orientation = "portrait";
      }
    },
    UPDATE_RESIZES(state) {
      state.window.width = window.innerWidth;
      state.window.height = window.innerHeight;
    },
    CHECK_IS_MOBILE_DEVICE(state) {
      if (navigator.userAgent.toLowerCase().indexOf("android") > -1 || (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)) {
        state.isMobileDevice = true
      }
    }
  },
};

export default UserAgent;
