<template>
  <div
    id="app"
    :class="[
      { 'screentest': $store.state.Screentest.isScreentest },
      browser.name.toLowerCase(),
      browser.os.toLowerCase(),
    ]"
  >
    <router-view />
    <ISI />
    <transition name="fade">
      <RotateLock
        v-if="UserAgent.orientation === 'landscape' && (UserAgent.window.width <= 1023 || UserAgent.isMobileDevice)"
      />
    </transition>
    <div class="wrapper-left" />
    <div class="wrapper-right" />
    <div class="wrapper-bottom">
      <div class="container-wrapper">
        <div class="copyright">© 2024 Haymarket Media, Inc. All rights reserved.</div>
        <div>
          <a href="https://www.haymarketmediaus.com/haymarket-media-inc-privacy-policy/" class="ppol" target="_blank">Privacy Policy</a>
          <a href="https://www.haymarketmediaus.com/haymarket-media-inc-print-and-digital-advertising-terms-and-conditions/" target="_blank">Terms & Conditions</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import { detect } from "detect-browser"

import { NoParentScrollMixin } from "./mixins/NoParentScrollMixin"
import HandleOrientation from "./mixins/HandleOrientation"

import RotateLock from "./components/RotateLock/RotateLock"
import ISI from './components/ISI/ISI'

export default {
  name: "App",
  components: {
    ISI,
    RotateLock,
  },
  mixins: [NoParentScrollMixin, HandleOrientation],
  data() {
    return {
      browser: null,
    };
  },
  computed: {
    ...mapState(["UserAgent"]),
  },
  watch: {
    $route() {
      /**
       * Reset (set to initial state) final quizzes on route change
       */
      this.$store.dispatch("resetFinalQuizzes")
    },
  },
  methods: {
    routeAfterLeave() {
      window.scrollTo(0, 0)
    },

    calcVH() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    },
  },
  created() {
    if (!!this.$route.query.screentest) {
      this.$store.dispatch("setScreentest")
    }

    if (!!this.$route.query.isOpenedModal) {
      this.$store.dispatch("openModal")
    }

    if (!!this.$route.query.openedISI) {
      this.$store.dispatch("toggleISIState")
    }

    const browser = detect()
    if (browser) {
      this.browser = browser
    }
  },
  mounted() {
    this.calcVH()

    const isScreenTest = window.location.href.toLowerCase().indexOf('screentest') > -1;
    
    if(!isScreenTest) {
      window.addEventListener('resize', () => {
        this.$store.commit('UPDATE_RESIZES')
      })
    }
    this.$store.commit('CHECK_IS_MOBILE_DEVICE')
  },
}
</script>

<style lang="scss" src="./scss/main.scss"></style>
