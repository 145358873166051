<script>

import {mapState} from "vuex";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ButtonClickAnalytics from "@/mixins/ButtonClickAnalytics";

export default {
  name: "ResourcesSection",
  computed: {
    ...mapState(["UserAgent"]),
  },
  mixins: [ButtonClickAnalytics],
  methods: {
    scrollToResources() {
      const res = document.getElementById('resources')
      const resTop = res.offsetTop
      const topBarHeight = document.querySelector('.header-bar').offsetHeight
      const y = resTop - topBarHeight

      window.scrollTo({top: y, behavior: 'smooth'});
    },
    listenScroll() {
      const res = document.getElementById('resources')
      const resTop = res.offsetTop
      // const resHeight = res.offsetHeight
      const isiHeight = document.querySelector('.isi').offsetHeight
      // const topBarHeight = document.querySelector('.header-bar').offsetHeight
      const wrapBtHeight = document.querySelector('.wrapper-bottom').offsetHeight
      const resBtn = document.querySelector('.res-btn')
      const minBottom = resTop - window.innerHeight + isiHeight + wrapBtHeight + 200
      // const minTop = resTop + resHeight - topBarHeight - 200

      // if (window.scrollY > minBottom && window.scrollY < minTop) {
      //   resBtn.classList.add('hide')
      //   resBtn.classList.remove('bottom')
      // } else if (window.scrollY >= minTop) {
      //   resBtn.classList.add('bottom')
      //   resBtn.classList.remove('hide')
      // } else {
      //   resBtn.classList.remove('bottom')
      //   resBtn.classList.remove('hide')
      // }

      if (window.scrollY > minBottom) {
        resBtn.classList.add('hide')
      } else {
        resBtn.classList.remove('hide')
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.listenScroll)
    window.addEventListener('scroll', this.listenScroll)

    ScrollTrigger.create({
      trigger: this.$el,
      start: `top center`,
      end: `bottom center`,
      once: true,
      onToggle: ({progress, direction, isActive}) => {
        // if (isActive && typeof window.dataLayer !== 'undefined' && window.dataLayer) {
        //   window.dataLayer.push({
        //     event: "section-view",
        //     section: 'Overview',
        //     subsection: "-",
        //   });
        // }
      },
    });
  },
};
</script>

<template>
  <div
    id="resources"
    class="resources container-full"
    :class="{'screentest': $store.state.Screentest.isScreentest}"
  >
    <div class="container">
      <h2 class="m-lr-auto mb-48">QINLOCK Resources</h2>
      <div class="list">
        <!-- First Resourse -->
        <div class="col col-1">
          <div class="item item-1">
            <img src="@/assets/img/res/res-1.jpg" alt="">
            <div>
              <h3>QINLOCK Brochure for Healthcare Professionals</h3>
              <div class="descr">
                <div class="text">
                  An overview of the efficacy and safety of QINLOCK for prescribers
                </div>
                <a href="https://www.qinlockhcp.com/content/files/QINLOCK-HCP-brochure.pdf" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Brochure')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div>
        <!-- First Resourse Ends -->
        <!-- Second Resourse -->
        <div class="col col-2">
          <div class="item item-4">
            <img src="@/assets/img/res/res-4.jpg" alt="">
            <div>
              <h3>QINLOCK Dosing, Administration, and Adverse Reaction Management Guide</h3>
              <div class="descr">
                <div class="text">
                  A guide to starting patients on QINLOCK and managing common treatment-related adverse events
                </div>
                <a href="https://www.qinlockhcp.com/content/files/QINLOCK-initiation-guide.pdf" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Adverse Reaction Mgmt')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Second Resourse Ends -->
        <!-- Third Resourse -->
        <!-- Comment for future reference -->
        <!-- <div class="col col-1">
          <div class="item item-2">
            <img src="@/assets/img/res/res-2.jpg" alt="">
            <div>
              <h3>INVICTUS Trial Overview</h3>
              <div class="descr">
                <div class="text">
                  Highlights of the methodology and key findings of the INVICTUS clinical trial
                </div>
                <a href="https://www.qinlockhcp.com/content/files/invictus-trial-reprint-carrier.pdf" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Trial Overview')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Third Resourse Ends -->
        <!-- Forth Resourse -->
        <div class="col col-1">
          <div class="item item-5">
            <img src="@/assets/img/res/res-5.png" alt="">
            <div>
              <h3>INVICTUS Trial Publication in <i>Lancet Oncology</i></h3>
              <div class="descr">
                <div class="text">
                  <span>The INVICTUS trial publication demonstrating the efficacy and safety of QINLOCK in <nobr>4th-line</nobr> advanced GIST</span>
                </div>
                <a href="https://www.thelancet.com/action/showPdf?pii=S1470-2045%2820%2930168-6" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Trial Paper')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Forth Resourse Ends -->
        <!-- Fifth Resourse -->
        <div class="col col-2">
          <div class="item item-3">
            <img src="@/assets/img/res/res-3.jpg" alt="">
            <div>
              <h3>Deciphera AccessPoint Enrollment Form</h3>
              <div class="descr">
                <div class="text">
                  A simple form that connects your patients to the many services and financial support options of Deciphera AccessPoint
                </div>
                <a href="https://www.decipheraaccesspoint.com/content/files/Deciphera-AccessPoint-enrollment-form.pdf" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Enrollment Form')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Fifth Resourse Ends -->
        <!-- Sixth Resourse -->
        <div class="col col-1">
          <div class="item item-6">
            <img src="@/assets/img/res/res-6.jpg" alt="">
            <div>
              <h3>Product Fact Sheet and Ordering Information</h3>
              <div class="descr">
                <div class="text">
                  A quick-reference resource with information about QINLOCK and the specialty pharmacy and distribution network
                </div>
                <a href="https://www.decipheraaccesspoint.com/content/files/QINLOCK-provider-product-fact-sheet.pdf" class="org-btn btn-sm" target="_blank" @click="buttonClickDataLayerPush('Download - Fact Sheet')">DOWNLOAD PDF</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Sixth Resourse Ends -->
      </div>
      <div class="list">
      </div>
    </div>
    <img class="res-btn" src="@/assets/img/icon/resources-btn.svg" alt="" @click="scrollToResources">
  </div>
</template>

<style lang="scss" scoped>
.resources {
  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: $mobile-max) {
    padding-bottom: 25px;
  }

  .res-btn {
    position: fixed;
    right: 60px;
    bottom: 195px;
    cursor: pointer;
    transition: 0.5s ease;

    @media (max-width: $tablet-max) {
      display: none;
    }

    &.bottom {
      bottom: 60px;
    }

    &.hide {
      opacity: 0;
      bottom: -40px;
      transform: scale(0);
    }
  }

  h2 {
    @media (max-width: $mobile-max) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: $mobile-max) {
      flex-direction: column;
    }

    .col {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: $mobile-max) {
        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(5) {
          order: 3;
        }

        &:nth-child(2) {
          order: 4;
        }

        &:nth-child(4) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }
      }

      &-1 {
        border-right: 1px solid $border-color;
        padding-right: 30px;

        @media (max-width: $tablet-max) {
          padding-right: 16px;
        }

        @media (max-width: $mobile-max) {
          width: 100%;
          padding-right: 0;
          border: none;
        }
      }

      &-2 {
        padding-left: 30px;

        @media (max-width: $tablet-max) {
          padding-left: 16px;
        }

        @media (max-width: $mobile-max) {
          width: 100%;
          padding-left: 0;
        }
      }

      .item {
        display: flex;
        align-items: center;

        &-1,
        &-2,
        &-4,
        &-5 {
          padding-bottom: 60px;

          @media (max-width: $tablet-max) {
            padding-bottom: 30px;
          }
        }

        &-1,
        &-2,
        &-4,
        &-5,
        &-6 {
          @media (max-width: $mobile-max) {
            padding-bottom: 40px;
          }
        }

        img {
          max-width: 40%;
          margin-right: 5%;
          box-shadow: 0 4px 20px 0 rgb(94 39 81 / 20%);

          @media (max-width: $tablet-max) {
            max-width: 140px;
          }
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            margin-bottom: 12px;

            @media (min-width: $desktop-min) and (max-width: 1150px) {
              font-size: 16px;
              margin-bottom: 8px;
            }

            @media (max-width: $tablet-max) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: -0.5px;
              margin-bottom: 8px;
            }

            @media (max-width: $mobile-max) {
              letter-spacing: unset;
              margin-bottom: 4px;
            }
          }

          .descr {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text {
              line-height: 125%;
              color: $text1;
              font-size: 14px;
              margin-bottom: 36px;

              @media (min-width: $desktop-min) and (max-width: 1150px) {
                font-size: 13px;
                margin-bottom: 20px;
              }

              @media (max-width: $tablet-max) {
                font-size: 11px;
                line-height: 16px;
                margin-bottom: 8px;
                letter-spacing: -0.1px;
              }

              @media (max-width: $mobile-max) {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: unset;
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}

</style>