import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VScrollLock from "v-scroll-lock";

// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';

import VueScrollactive from 'vue-scrollactive';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(ScrollToPlugin);

Vue.config.productionTip = false;

Vue.use(VScrollLock);
Vue.use(VueScrollactive);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
