import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import router from "@/router";
import ISI from "@/store/modules/ISI";
import Modal from "@/store/modules/Modal";
import Screentest from "@/store/modules/Screentest";
import Quiz from "@/store/modules/Quiz";
import UserAgent from "@/store/modules/UserAgent";

import animationsData from "@/store/data/animationsData";

const regexDev = /localhost|127.0.0.1|development|192.168./;
const regexStage = /staging/;
const location = window.location.href;
const environment = regexDev.test(location) ? "dev" :
  regexStage.test(location) ? "staging" : "prod";

const vuexLocal = new VuexPersistence({
  modules: ["Quiz"],
  key: `${environment}_janssen_stelara_edetail`
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    animationsData,
    rotateLock: null,
  },
  mutations: {
    SET_ROTATE_LOCK(state, value) {
      state.rotateLock = value;
    },
  },
  actions: {},
  modules: {
    ISI,
    Modal,
    Screentest,
    Quiz,
    UserAgent,
  },
  plugins: !(router.currentRoute.query.screentest || environment === "dev" || environment === "staging") ? [vuexLocal.plugin] : [],
});
