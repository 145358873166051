import router from "@/router";

/**
 * Quiz module
 * Contains quiz items in state
 * Have functionality for polls api
 * To have localStorage linked with state, setup "vuex-persist" npm package in index.js of Vuex
 */

// import axios from "axios";

/**
 * Event name for GTM analytics
 * @const {string}
 */
const analEventName = "poll";

/**
 * Project slug for api
 * @const {string}
 */
const projectSlug = "janssen_stelara_edetail";

/**
 * Patient slug for api
 * @const {string}
 */
const patient = "home";

/**
 * Push analytics data to GTM layerData
 * @param {object} analData analytics data object (contains quizId and analValue)
 */
const pushAnalData = (analData) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: analEventName,
      Category: analEventName,
      Action: `${analData.page}-question-${analData.quizIdOnPage}`,
      Label: analData.analValue,
    });
    // console.log(
    //   "%c Pushed new data to dataLayer:",
    //   "background: #222; color: #bada55",
    //   window.dataLayer
    // );
  }
};

const quiz = {
  state: () => ({
    quizItems: [
      {
        title:
          "How important is safety data in your consideration for the treatment of patients with moderately to severely active <nobr>Crohn’s disease</nobr> (CD)?", // Main title. HTML tags could be used here
        multi: false, // Determines if the question should accept multiple answers (type button (single) answer or type checkbox (multiple) answers)
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`, // Used for quizzes with Stats ON. Post user selection to API, get current stats. E.g: https://api.prihcs.com/polls/alk_odactra_pj/susan/3/
        show_answer_stats: false, // Shows percentage bars after the answer ( requires api.prihcs.com URL )
        show_skip: false, // Enable "Skip" button
        show_correct: false, // Mark correct and incorrect answers after the user answers the question
        isLoading: false, // Load state, use with api requests
        idOnPage: 1,
        buttons: [
          {
            order: 0, // Sort order of the button
            key: "a", // Data for API
            text: "Very important", // Button label
            anal_value: "A", // Data for GTM dataLayer
            isCorrect: true, // Indicates the correct/incorrect answer
            isChecked: false, // Prop for checkboxes lists
            stats: 0, // Polling stats value (to be updated with API request)
          },
          {
            order: 1,
            key: "b",
            text: "Somewhat important",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 2,
            key: "c",
            text: "Not important",
            anal_value: "С",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false, // State of the Quiz (answered/no)
      },
      {
        title:
          "After seeing the STELARA® efficacy and safety data, including clinical response and remission data, combined CD+UC safety data through <nobr>1 year,</nobr> and CD safety data up to 5 years, how likely are you to consider STELARA® as a first-line* biologic treatment for adult patients with moderately to severely active <nobr>CD or UC?</nobr>",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        hint:
          "<span>*</span><span>In patients who failed or were intolerant to immunomodulators or corticosteroids.</span>",
        idOnPage: 2,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Very likely",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 1,
            key: "b",
            text: "Somewhat likely",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 2,
            key: "c",
            text: "Not likely",
            anal_value: "С",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title:
          "Of the data you reviewed today, which of the following did you find to be the most impactful?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        idOnPage: 3,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Combined CD+UC safety data through 1 year",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 1,
            key: "b",
            text: "5-year data <nobr>from the open-label LTE</nobr>",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 2,
            key: "c",
            text: "Additional safety data at 1 year in CD and UC",
            anal_value: "С",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 3,
            key: "d",
            text: "Additional safety data <nobr>up to 5 years</nobr>",
            anal_value: "D",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title: "Now, would you like to view the UC safety data?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: true,
        idOnPage: 4,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Yes",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            routerLinkToRouteURL: "/uc/",
            isNotScroll: true,
          },
          {
            order: 1,
            key: "b",
            text: "No",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title: "Would you like to view the heritage data for STELARA®?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: true,
        idOnPage: 5,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Yes",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            routerLinkToRouteURL: "/heritage/",
            isNotScroll: true,
          },
          {
            order: 1,
            key: "b",
            text: "No",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title:
          "How important is safety data when considering a first-line* biologic for patients with moderately to severely active <nobr>ulcerative colitis (UC)</nobr>?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        hint:
          "<span>*</span><span>In patients who failed or were intolerant to immunomodulators or corticosteroids.</span>",
        idOnPage: 1,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Very important",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 1,
            key: "b",
            text: "Somewhat important",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 2,
            key: "c",
            text: "Not important",
            anal_value: "С",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title:
          "After seeing the STELARA® safety data, including combined CD+UC safety data through 1 year and UC safety data through <br class='only-desktop' />2 years, how has your perception of STELARA® changed?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        idOnPage: 2,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "My perception of STELARA® has improved",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 1,
            key: "b",
            text: "My perception of STELARA® has stayed the same",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
          {
            order: 2,
            key: "c",
            text: "My perception of STELARA® has worsened",
            anal_value: "С",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
      {
        title: "Now, would you like to view the CD safety data?",
        multi: false,
        api_url: `https://api.prihcs.com/polls/${projectSlug}/${patient}/0/`,
        show_answer_stats: false,
        show_skip: false,
        show_correct: false,
        isLoading: false,
        isFinal: true,
        idOnPage: 3,
        buttons: [
          {
            order: 0,
            key: "a",
            text: "Yes",
            anal_value: "A",
            isCorrect: true,
            isChecked: false,
            stats: 0,
            routerLinkToRouteURL: "/cd/",
            isNotScroll: true,
          },
          {
            order: 1,
            key: "b",
            text: "No",
            anal_value: "B",
            isCorrect: true,
            isChecked: false,
            stats: 0,
          },
        ],
        isSubmitted: false,
      },
    ],
  }),
  mutations: {
    SUBMIT_ANSWER(state, payload) {
      state.quizItems[payload.quizId].isSubmitted = true;
      state.quizItems[payload.quizId].buttons[
        payload.buttonId
      ].isChecked = !state.quizItems[payload.quizId].buttons[payload.buttonId]
        .isChecked;
    },

    TOGGLE_CHECKBOX(state, payload) {
      state.quizItems[payload.quizId].buttons[
        payload.checkboxId
      ].isChecked = !state.quizItems[payload.quizId].buttons[payload.checkboxId]
        .isChecked;
    },

    SUBMIT_CHECKBOX_QUIZ(state, payload) {
      state.quizItems[payload.quizId].isSubmitted = true;
    },

    SET_QUIZ_STATS(state, payload) {
      /**
       * Confusing things here:
       * Parsing api request obj into quiz buttons
       * Calculating summary votes in absolute value
       */
      let sum = 0;
      state.quizItems[payload.quizId].buttons.forEach((quizItem) => {
        quizItem.stats = payload.stats[0].home[payload.quizId][quizItem.key];
        sum += parseInt(payload.stats[0].home[payload.quizId][quizItem.key]);
      });
      /**
       * Converting absolute value votes => to percentage
       */
      state.quizItems[payload.quizId].buttons.forEach((quizItem) => {
        quizItem.stats = Math.round(
          (payload.stats[0].home[payload.quizId][quizItem.key] / sum) * 100
        );
      });
    },

    SET_LOADING_STATUS(state, payload) {
      state.quizItems[payload.quizId].isLoading = payload.isLoading;
    },

    /**
     * Set initial state (un-submitted) to quizzes with "isFinal" flag
     * @param state
     * @param payload
     * @constructor
     */
    RESET_FINAL_QUIZZES(state, payload) {
      const finalQuizzes = state.quizItems.filter((item) => item.isFinal);
      finalQuizzes.forEach((item) => {
        item.isSubmitted = false;
        item.buttons.forEach((button) => {
          button.isChecked = false;
        });
      });
    },

    PUSH_ROUTER(state, payload) {
      const routerLink =
        state.quizItems[payload.quizId].buttons[payload.buttonId]
          .routerLinkToRouteURL;
      if (routerLink) {
        setTimeout(() => {
          router.push({ path: routerLink });
        }, 300);
      }
    },
  },
  actions: {
    submitAnswer(context, payload) {
      context.commit("SUBMIT_ANSWER", payload);
      pushAnalData(payload);
      context.commit("PUSH_ROUTER", payload);
    },

    toggleCheckbox(context, payload) {
      context.commit("TOGGLE_CHECKBOX", payload);
    },

    submitCheckboxQuiz(context, payload) {
      context.commit("SUBMIT_CHECKBOX_QUIZ", payload);
      payload.analValue = payload.analValue.map((item) => item.anal_value);
      pushAnalData(payload);
    },

    resetFinalQuizzes(context) {
      context.commit("RESET_FINAL_QUIZZES");
    },

    // getQuizStats({ commit, state }, payload) {
    //   const submittedVariant =
    //     state.quizItems[payload.quizId].buttons[payload.buttonId].key;
    //   payload.isLoading = true;
    //   commit("SET_LOADING_STATUS", payload);
    //   axios
    //     .post(state.quizItems[payload.quizId].api_url, {
    //       [submittedVariant]: 1,
    //     })
    //     .then((response) => {
    //       payload.stats = response.data;
    //       commit("SET_QUIZ_STATS", payload);
    //     })
    //     .then(() => {
    //       commit("SUBMIT_ANSWER", payload);
    //       payload.isLoading = false;
    //       commit("SET_LOADING_STATUS", payload);
    //       pushAnalData(payload);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
  },
  getters: {
    getCheckedCheckboxesById: (state) => (id) =>
      state.quizItems[id].buttons.filter((button) => button.isChecked),
  },
};

export default quiz;
