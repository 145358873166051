const $body = document.querySelector("body");
let scrollPosition = 0;

export default {
  methods: {
    lockBodyScroll() {
      scrollPosition = window.pageYOffset;
      $body.style.overflow = "hidden";
      $body.style.position = /MSIE \d|Trident.*rv:/.test(navigator.userAgent)
        ? ""
        : "fixed";
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = "100%";
    },
    unLockBodyScroll() {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("position");
      $body.style.removeProperty("top");
      $body.style.removeProperty("width");
      if (scrollPosition !== 0) {
        window.scrollTo(0, scrollPosition);
      }
    },
  },
};
