<template>
  <div id="footer" class="footer container-full">
    <div class="container">
      <div class="row">
        <img src="@/assets/img/logo/client.svg" alt="">
        <div>
          <p>
            © 2024 Deciphera Pharmaceuticals. The QINLOCK<sup>®</sup> word mark and logo are registered trademarks of Deciphera Pharmaceuticals, LLC. Deciphera, Deciphera Pharmaceuticals, Deciphera AccessPoint, the Deciphera logo, and the Deciphera AccessPoint logo are trademarks of Deciphera Pharmaceuticals, LLC. All rights reserved.
          </p>
          <p>
            DCPH-P01178 04/24
          </p>
        </div>
      </div>
      <p>
        This <i>Cancer Therapy Advisor Product Hub</i> is produced as a basic reminder of important information for healthcare professionals. Readers are advised to consult manufacturers and specialists if questions arise about specific products, treatments, or diseases. The publisher and editors do not assume liability for any errors or omissions. <i>Cancer Therapy Advisor</i> and <i>Product Hub</i> are registered trademarks of Haymarket Media, Inc.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterSection"
}
</script>

<style lang="scss">
.footer {
  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: $tablet-max) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  p {
    font-size: 13px;
    color: #2b2b2b;
    line-height: 140%;
  }

  .row {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid $border-color;

    @media (max-width: $mobile-max) {
      flex-direction: column;
    }

    img {
      margin-right: 50px;

      @media (max-width: $mobile-max) {
        width: 188px;
        margin: 0 auto 30px;
      }

      & + div {
        @media (max-width: $mobile-max) {
          text-align: center;
        }
      }
    }
  }
}

</style>