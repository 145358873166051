<template>
  <div class="isi-btn">
    <svg class="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.18182 12C2.18182 6.57757 6.57757 2.18182 12 2.18182C17.4224 2.18182 21.8182 6.57757 21.8182 12C21.8182 17.4224 17.4224 21.8182 12 21.8182C6.57757 21.8182 2.18182 17.4224 2.18182 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM7.63636 10.9091C7.03387 10.9091 6.54545 11.3975 6.54545 12C6.54545 12.6025 7.03387 13.0909 7.63636 13.0909H16.3636C16.9661 13.0909 17.4545 12.6025 17.4545 12C17.4545 11.3975 16.9661 10.9091 16.3636 10.9091H7.63636Z" fill="#A3A3A3"/>
    </svg>
    <svg class="open" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.18182C6.57757 2.18182 2.18182 6.57757 2.18182 12C2.18182 17.4224 6.57757 21.8182 12 21.8182C17.4224 21.8182 21.8182 17.4224 21.8182 12C21.8182 6.57757 17.4224 2.18182 12 2.18182ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 6.54545C12.6025 6.54545 13.0909 7.03387 13.0909 7.63636V10.9091H16.3636C16.9661 10.9091 17.4545 11.3975 17.4545 12C17.4545 12.6025 16.9661 13.0909 16.3636 13.0909H13.0909V16.3636C13.0909 16.9661 12.6025 17.4545 12 17.4545C11.3975 17.4545 10.9091 16.9661 10.9091 16.3636V13.0909H7.63636C7.03387 13.0909 6.54545 12.6025 6.54545 12C6.54545 11.3975 7.03387 10.9091 7.63636 10.9091H10.9091V7.63636C10.9091 7.03387 11.3975 6.54545 12 6.54545Z" fill="#A3A3A3"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ISIArrow"
}
</script>